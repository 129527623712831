<template>
    <div class="grid grid-cols-12 gap-3">
        <div class="col-span-6">
            <input
                v-model="filters[searchQueryKey]"
                type="text"
                name="search"
                class="form-control form-control-lg w-full"
                :placeholder="placeholder"
                autocomplete="off"
                @input="onUpdate"
            />
        </div>
        <div class="col-span-3 flex items-center">
            <label class="form-check-label ml-0 sm:ml-2 flex-none" for="show-syncable">
                Только синхронизируемые корабли
            </label>
            <input
                id="show-syncable"
                v-model="filters[syncableQueryKey]"
                true-value="1"
                false-value="0"
                class="show-code form-check-input mr-0 ml-3 flex-none"
                type="checkbox"
                @input="onUpdate"
            />
        </div>
        <div class="col-span-3 flex items-center">
            <label class="form-check-label ml-0 sm:ml-2 flex-none" for="show-without-scheme">
                Только теплоходы без схем
            </label>
            <input
                id="show-without-scheme"
                v-model="filters[withoutSchemeQueryKey]"
                true-value="1"
                false-value="0"
                class="show-code form-check-input mr-0 ml-3 flex-none"
                type="checkbox"
                @input="onUpdate"
            />
        </div>
    </div>
</template>

<script>
let searchTimer = null;

export default {
    name: 'ShipContentSearch',
    props: {
        placeholder: {
            type: String,
            default: 'Поиск',
        },
        searchQueryKey: {
            type: String,
            default: 'searchString',
        },
        syncableQueryKey: {
            type: String,
            default: 'syncable',
        },
        withoutSchemeQueryKey: {
            type: String,
            default: 'withoutScheme',
        },
    },
    data() {
        return {
            filters: {
                [this.searchQueryKey]: null,
                [this.syncableQueryKey]: 1,
                [this.withoutSchemeQueryKey]: 0,
            },
        };
    },
    created() {
        this.fillSearchOnInit();
    },
    beforeUnmount() {
        searchTimer = null;
    },
    methods: {
        onUpdate() {
            clearTimeout(searchTimer);
            // TODO очему это сделано так? Это должно было вызывать событие наверх!
            searchTimer = setTimeout(() => {
                this.$router.push({ query: this.filters });
            }, 800);
        },
        fillSearchOnInit() {
            for (const [key, value] of Object.entries(this.$route.query)) {
                this.filters[key] = value;
            }
        },
    },
};
</script>

<style></style>
