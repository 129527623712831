<template>
    <ShipContentSearch class="mb-4" />
    <ContentTable
        :loading="loading"
        :headers="tableExample"
        :table-rows="tableData"
        :table-actions="tableActions"
        :meta="pagination"
        @edit="editAction"
        @delete="deleteAction"
    />
</template>
;
<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import ContentTable from '@/components/tables/ContentTable';
import { mapGetters } from 'vuex';
import ShipContentSearch from '@/components/ships/ShipContentSearch.vue';
import { pageMixin } from '@/mixins/pageMixin';

export default {
    name: 'Ships',
    components: { ShipContentSearch, ContentTable },
    mixins: [errorResponse, pageMixin],
    data() {
        return {
            loading: false,
            modalOpen: false,
            tableExample: [
                { field: 'id', label: '#' },
                { field: 'title', label: 'Название судна' },
            ],
            tableActions: [{ name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' }],
            addButtonDelete: false,
            tableData: [],
            pagination: null,
            modalType: null,
            modalTitle: null,
            modalCallback: null,
            modalData: null,
        };
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Теплоходы');
        await this.fetchShips(this.$route.query);
    },
    computed: {
        ...mapGetters({ getUser: 'user/getUser' }),
    },
    methods: {
        async fetchShips(params) {
            this.loading = true;

            try {
                const { data } = await this.axios.get('/ships', { params });
                this.tableData = data.data;
                this.pagination = data.meta;

                const rolesCanDestroyPosts = this.$route.meta.destroy_posts ?? [];

                if (rolesCanDestroyPosts.includes(this.getUser.role) && !this.addButtonDelete) {
                    const buttonDelete = {
                        name: 'delete',
                        title: 'удалить',
                        class: 'bg-red-400',
                        icon: 'Trash2Icon',
                        cbName: 'delete',
                    };

                    this.tableActions.push(buttonDelete);
                    this.addButtonDelete = true;
                }

                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch ships: ' + ex);
            }
        },
        deleteAction(row) {
            this.modalData = row;
            this.modalType = 'delete';
            this.modalOpen = true;
        },
        editAction(row) {
            this.modalType = 'update';
            // this.$router.push({ name: 'ship-edit', params: { id: row.id } });
            this._pMTransitionToEditPageWithQuery('ship-edit', { id: row.id });
        },
        onDeleteModalAccepted(data) {
            this.axios
                .delete('/ships/' + data.id)
                .then((res) => {
                    this.fetchShips(this.$route.query);
                    this.modalOpen = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
    },
    async beforeRouteUpdate(to, from, next) {
        await this.fetchShips({ ...to.query });
        next();
    },
};
</script>
;
