<template>
    <div class="flex justify-end mt-10">
        <a
            v-if="slug"
            target="_blank"
            class="btn btn-outline-secondary w-30 mr-2"
            :class="{ 'cursor-wait pointer-events-none opacity-50': loading }"
            :href="`https://kruizy-rossii.com/ships/${slug}/`"
        >
            Перейти на страницу
        </a>
    </div>

    <form method="post" class="grid grid-cols-12 gap-6 mt-4" :class="{ 'disabled-form': loading }">
        <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
            <Preloader />
        </div>

        <div class="relative col-span-6">
            <label for="form-title" class="form-label">
                Название судна
                <sup v-if="v$.form.title.required" class="text-theme-6">*</sup>
            </label>
            <label v-if="v$.form.title.$errors.length" for="form-title" class="form-input-text-error">
                {{ getOverrideMessage(v$.form.title.$errors[0].$message) }}
            </label>
            <input
                id="form-title"
                v-model="form.title"
                type="text"
                class="form-control w-full"
                :class="{ 'form-input-error': v$.form.title.$errors.length }"
                placeholder=""
                disabled
                autocomplete="off"
            />
        </div>

        <div class="relative col-span-6">
            <label for="form-slug" class="form-label">
                Ссылка
                <sup v-if="v$.form.slug.required" class="text-theme-6">*</sup>
            </label>
            <label v-if="v$.form.slug.$errors.length" for="form-slug" class="form-input-text-error">
                {{ getOverrideMessage(v$.form.slug.$errors[0].$message) }}
            </label>
            <input
                id="form-slug"
                v-model="form.slug"
                type="text"
                class="form-control w-full"
                :class="{ 'form-input-error': v$.form.slug.$errors.length }"
                placeholder=""
                autocomplete="off"
            />
        </div>

        <div class="col-span-6">
            <label for="form-type" class="form-label">Тип</label>
            <select id="form-type" v-model="form.type" name="type" class="form-select sm:mr-2" autocomplete="off">
                <option v-for="(opt, optIdx) in shipTypes" :key="optIdx" :value="opt.id">
                    {{ opt.title }}
                </option>
            </select>
        </div>

        <div class="col-span-6">
            <label for="form-ship_class" class="form-label">Класс судна</label>
            <select
                id="form-ship_class"
                v-model="form.class"
                name="type"
                class="form-select sm:mr-2"
                autocomplete="off"
            >
                <option v-for="(opt, optIdx) in shipClasses" :key="optIdx" :value="opt.id">
                    {{ opt.title }}
                </option>
            </select>
        </div>

        <div class="col-span-4">
            <label for="form-decks_number" class="form-label">Текстовое количество палуб (по проекту)</label>
            <input
                id="form-decks_number"
                v-model="form.decks_number"
                type="text"
                class="form-control w-full"
                placeholder=""
                autocomplete="off"
            />
        </div>

        <div class="col-span-4">
            <label for="form-decks_number_int" class="form-label">Числовое количество палуб (по проекту)</label>
            <input
                id="form-decks_number_int"
                v-model.number="form.decks_number_int"
                type="number"
                min="0"
                class="form-control w-full"
                placeholder=""
                autocomplete="off"
            />
        </div>

        <div class="col-span-4">
            <label for="form-capacity" class="form-label">Вместимость (чел.)</label>
            <input
                id="form-capacity"
                v-model.number="form.capacity"
                type="number"
                min="0"
                class="form-control w-full"
                placeholder=""
                autocomplete="off"
            />
        </div>

        <div class="col-span-6">
            <label for="form-country_manufacturer" class="form-label">Страна производитель</label>
            <input
                id="form-country_manufacturer"
                v-model="form.country_manufacturer"
                type="text"
                class="form-control w-full"
                placeholder=""
                autocomplete="off"
            />
        </div>

        <div class="col-span-6 relative">
            <label for="form-modern_date_at" class="form-label">Дата модернизации судна</label>
            <input id="form-modern_date_at" v-model="form.modern_date_at" type="date" class="form-control w-full" />
        </div>

        <div class="col-span-6" title="SEO тэг для каждого круиза на этой странице">
            <label for="form-hidden_tag" class="form-label">Скрытые тэги</label>

            <Multiselect
                id="form-hidden_tag"
                v-model="form.hidden_tag"
                :options="form.hidden_tag"
                mode="tags"
                placeholder="SEO тэги для каждого круиза на этой странице"
                :hide-selected="false"
                :close-on-select="false"
                :native="false"
                :searchable="true"
                :create-option="true"
                no-results-text="Введите новый тэг и Enter чтобы создать"
                no-options-text="Введите новый тэг и Enter чтобы создать"
            />
        </div>

        <div class="col-span-3 flex items-center pt-6 mb-5">
            <input
                id="form-syncable"
                v-model="form.syncable"
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox"
            />
            <label for="form-syncable" class="form-check-label">Включить синхронизацию круизов</label>
        </div>

        <div class="col-span-3 flex items-center pt-6 mb-5">
            <input
                id="form-api_update_disabled"
                v-model="form.api_update_disabled"
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox"
            />
            <label for="form-api_update_disabled" class="form-check-label"
                >Отключить синхронизацию данных корабля</label
            >
        </div>

        <div class="col-span-12">
            <label class="form-label">Описание</label>
            <CustomWyswig v-model="form.description" />
        </div>

        <div class="col-span-6">
            <div class="relative">
                <label class="form-label">
                    Изображение
                    <sup v-if="v$.form.image.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.image.$errors.length" class="form-image-text-error">
                    {{ getOverrideMessage(v$.form.image.$errors[0].$message) }}
                </label>
                <UploadImageNew v-model="form.image"></UploadImageNew>
            </div>
        </div>

        <div class="col-span-12">
            <div class="mt-3">
                <label class="form-label">Галерея</label>
                <UploadMultiImageNew v-model="form.images" v-model:delete="form.images_delete"></UploadMultiImageNew>
            </div>
        </div>

        <div class="col-span-12">
            <label class="form-label font-bold">Проект и тип</label>
            <ShipCustomFields
                v-if="customFields.type"
                v-model="customFields.type"
                :meta="null"
                :headers="customFieldsHeaders.type"
            />
        </div>

        <div class="col-span-12">
            <label class="form-label font-bold">Технические параметры судна</label>
            <ShipCustomFields
                v-if="customFields.tech"
                v-model="customFields.tech"
                :meta="null"
                :headers="customFieldsHeaders.tech"
            />
        </div>

        <div class="col-span-6">
            <label class="form-label font-bold">Услуги, предоставляемые на борту</label>
            <ShipCustomFields
                v-if="customFields.services"
                v-model="customFields.services"
                :meta="null"
                :headers="customFieldsHeaders.services"
            />
        </div>

        <div class="col-span-6">
            <label class="form-label font-bold">Что входит в стоимость</label>
            <ShipCustomFields
                v-if="customFields.included"
                v-model="customFields.included"
                :meta="null"
                :headers="customFieldsHeaders.included"
            />
        </div>

        <div class="col-span-12">
            <label class="form-label font-bold">SEO Параметры</label>
            <ShipCustomFields v-if="seo" v-model="seo" :meta="null" :headers="seoHeaders" />
        </div>

        <div class="col-span-12 flex items-center justify-end mt-5">
            <router-link to="#" class="btn btn-secondary w-24 mb-2 mr-2" @click.prevent="redirectToMainPage"
                >Отмена</router-link
            >
            <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                Сохранить и выйти
            </button>
            <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
        </div>
    </form>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { errorResponse } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import UploadImageNew from '@/components/upload-image/UploadImageNew';
import UploadMultiImageNew from '@/components/upload-image/UploadMultiImageNew';
import ShipCustomFields from '@/components/tables/ShipCustomFields';
import { helper as $h } from '@/utils/helper';
import { formPreparation } from '@/utils/object-to-form-data';
import { pageMixin } from '@/mixins/pageMixin';

export default {
    name: 'Edit',
    components: { Multiselect, Preloader, CustomWyswig, UploadImageNew, UploadMultiImageNew, ShipCustomFields },
    mixins: [errorResponse, pageMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            form: {
                title: { required },
                slug: { required },
                image: { required },
            },
        };
    },
    data() {
        return {
            loading: false,
            form: {
                title: null,
                slug: null,
                hidden_tag: [],
                image: null,
                images: null,
                type: null,
                class: null,
                decks_number: null,
                decks_number_int: null,
                syncable: 0,
                api_update_disabled: 0,
                capacity: null,
                modern_date_at: null,
                country_manufacturer: null,
                description: null,
                images_delete: [],
            },
            slug: null,
            shipTypes: null,
            shipClasses: null,
            seo: [],
            seoHeaders: [
                { field: 'code', label: 'Название параметра' },
                { field: 'value', label: 'Значение' },
            ],
            customFields: {
                type: [],
                tech: [],
                services: [],
                included: [],
            },
            customFieldsHeaders: {
                type: [
                    { field: 'name', label: 'Название' },
                    { field: 'value', label: 'Значение' },
                ],
                tech: [
                    { field: 'name', label: 'Название' },
                    { field: 'value', label: 'Значение' },
                ],
                services: [{ field: 'value', label: 'Значение' }],
                included: [{ field: 'value', label: 'Значение' }],
            },
        };
    },
    created() {
        this.$store.commit('main/setPageHeader', 'Теплоходы / Редактирование');
        this.fetchData();
    },
    methods: {
        getDefaultSeo() {
            return [
                { code: 'title', value: '' },
                { code: 'keywords', value: '' },
                { code: 'description', value: '' },
                { code: 'query', value: '' },
            ];
        },
        async fetchData() {
            this.loading = true;
            this.axios
                .get(`/ships/${this.$route.params.id}/edit`)
                .then(({ data }) => {
                    this.seo = [];
                    this.customFields = {
                        type: [],
                        tech: [],
                        services: [],
                        included: [],
                    };

                    for (let [key, val] of Object.entries(data.ship)) {
                        //     // добавляем поля для удаления картинки
                        //     if (key === 'teams' && val && val.length > 0) {
                        //         val = val.map(item => {
                        //             const concatVal = {
                        //                 uniqueId: Math.floor(Math.random() * 10000),
                        //                 image: null,
                        //             };
                        //             return { ...item, ...concatVal };
                        //         });
                        //     }
                        //     if (key === 'api_update_disabled' || key === 'is_popular') {
                        //         val = !!val;
                        //     }
                        this.form[key] = val;
                    }
                    data.ship.seo.forEach((item) => {
                        if (item.block) {
                            this.customFields[item.block].push(item);
                        } else {
                            this.seo.push(item);
                        }
                    });

                    if (!this.seo.length) {
                        this.seo = this.getDefaultSeo();
                    } else {
                        const defaultSeo = this.getDefaultSeo();
                        const listOfDefaultShipSeo = defaultSeo.map((field) => field.code);
                        const listOfShipSeo = this.seo.map((field) => field.code);

                        listOfDefaultShipSeo.forEach((defaultSeoField) => {
                            if (!listOfShipSeo.includes(defaultSeoField))
                                this.seo.push({ code: defaultSeoField, value: '' });
                        });
                    }

                    this.form.images = data.ship.images;
                    this.shipTypes = data.types;
                    this.slug = data.ship.slug;
                    // this.shipSurfaces = data.surfaces;
                    this.shipClasses = data.ship_classes;

                    this.loading = false;
                })
                .catch((ex) => {
                    console.log('cant fetch single data: ' + ex);
                    this.loading = false;
                    // this.$router.push('/error-page');
                });
        },
        async save(flag) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;
            this.form.seo = this.seo;
            this.form.seo = this.getPreparedCustomFields();
            const formData = formPreparation(this.form, { imagesAsObject: true });

            this.axios
                .post(`/ships/${this.$route.params.id}`, formData, {
                    params: {
                        _method: 'patch',
                    },
                })
                .then(({ data }) => {
                    if (flag) {
                        this.fetchData();
                        this.$notify(data.message);
                    } else {
                        this.redirectToMainPage();
                        // this.$router.push({ name: 'ships' });
                    }
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
        getPreparedCustomFields() {
            const customFIelds = [...this.seo];

            for (let [key, val] of Object.entries(this.customFields)) {
                val.forEach((item) => {
                    customFIelds.push({ ...item, block: key });
                });
            }

            return customFIelds;
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
        redirectToMainPage() {
            this._pMTransitionToEditPageWithQuery('ships');
        },
    },
};
</script>
